.top-articles {
	padding-top: 26px;

	h3 {
		margin-bottom: 68px;

		@media screen and (max-width: 768px) {
			margin-bottom: 30px;
		}
	}

	.pr-0 {
		@media screen and (max-width: 992px) {
			padding-right: 15px;
		}
	}

	article {
		@media screen and (max-width: 992px) {
			margin-bottom: 30px;
		}

		.main-image {
			display: inline-block;
			margin-left: 24px;
			cursor: pointer;

			@media screen and (max-width: 1200px) {
				margin-left: 0;
			}

			@media screen and (max-width: 768px) {
				display: block;
				width: 100%;
			}

			&.play-button {
				position: relative;

				&::before {
					background: rgba(0, 0, 0, 0.14) none repeat scroll 0 0;
				    border: 2px solid white;
				    border-radius: 100%;
				    content: "";
				    height: 90px;
				    left: 50%;
				    opacity: 0.9;
				    position: absolute;
				    top: 50%;
				    transform: translate(-50%, -50%);
				    width: 90px;
				    transition: opacity 0.5s;
				}

				&::after {
					border-bottom: 20px solid transparent;
				    border-left: 32px solid white;
				    border-top: 20px solid transparent;
				    content: "";
				    height: 0;
				    left: 50%;
				    opacity: 0.9;
				    position: absolute;
				    top: 50%;
				    transform: translate(-39%, -50%);
				    transition: opacity 0.5s ease 0s;
				}
			}

			img {
				width: 336px;
				height: 382px;
				object-fit: cover;

				@media screen and (max-width: 1200px) {
					width: 250px;
				}

				@media screen and (max-width: 992px) {
					height: 317px;
				}

				@media screen and (max-width: 768px) {
					width: 100%;
				}
			}

			&:hover,
			&:focus,
			&:active {
				&.play-button {
					&::before,
					&::after {
						opacity: 0.5;
						transition: opacity 0.5s;
					}
				}
			}
		}

		.article-content {
			display: inline-block;
			width: calc(100% - 403px);
			float: right;

			@media screen and (max-width: 1200px) {
				width: calc(100% - 269px);
			}

			@media screen and (max-width: 768px) {
				width: 100%;
				margin-top: 15px;
				margin-bottom: 30px;
			}

			h2 {
				margin-top: 0;
				font-size: 23px;
    			letter-spacing: 0.9px;
			}

			h3 {
				font-size: 13px;
			    letter-spacing: 0.6px;
			    line-height: 19px;
			    margin-bottom: 16px;
			    margin-top: 5px;
			}

			p {
				font-size: 13px;
			    letter-spacing: 0.6px;
			    line-height: 20px;
			    margin-bottom: 16px;
			    margin-top: 5px;
			    font-family: 'latolight';
			}
		}
	}

	aside {
		.article-small {
			display: inline-block;
		    margin-bottom: 25px;
		    padding-left: 5px;
		    width: 100%;

		    @media screen and (max-width: 992px) {
		    	width: 50%;
		    	float: left;
		    }

		    @media screen and (max-width: 500px) {
		    	min-height: 152px;
		    }

			.main-image {
				display: inline-block;
				float: left;

				@media screen and (max-width: 500px) {
					width: 100%;
				}

				&.play-button {
				position: relative;

					&::before {
						background: rgba(0, 0, 0, 0.14) none repeat scroll 0 0;
					    border: 2px solid white;
					    border-radius: 100%;
					    content: "";
					    height: 26px;
					    left: 50%;
					    opacity: 0.9;
					    position: absolute;
					    top: 50%;
					    transform: translate(-50%, -50%);
					    transition: opacity 0.5s ease 0s;
					    width: 26px;
					}

					&::after {
						border-bottom: 5px solid transparent;
					    border-left: 8px solid white;
					    border-top: 5px solid transparent;
					    content: "";
					    height: 0;
					    left: 50%;
					    opacity: 0.9;
					    position: absolute;
					    top: 50%;
					    transform: translate(-39%, -50%);
					    transition: opacity 0.5s ease 0s;
					}
				}

				img {
					display: block;
					width: 70px;
					height: 51px;
					object-fit: cover;

					@media screen and (max-width: 500px) {
						width: 100%;
						height: 100px;
					}
				}

				&:hover,
				&:focus,
				&:active {
					&.play-button {
						&::before,
						&::after {
							opacity: 0.5;
							transition: opacity 0.5s;
						}
					}
				}
			}

			.heading {
				color: black;
			    display: inline-block;
			    float: left;
			    font-size: 16px;
			    margin-left: 11px;
			    margin-top: 0;
			    max-width: calc(100% - 86px);
			    text-decoration: none;
			    width: auto;
			    line-height: 16px;

			    @media screen and (max-width: 768px) {
			    	font-size: 14px;
			    }

			    @media screen and (max-width: 500px) {
			    	display: block;
			    	width: 100%;
			    	margin-left: 0;
			    	max-width: 100%;
			    }

			    i {
			    	font-size: 12px;
				    margin-left: 8px;
				    vertical-align: middle;

				    @media screen and (max-width: 500px) {
				    	display: none;
				    }
			    }
			}

			span {
				display: inline-block;
				float: left;
				max-width: calc(100% - 86px);
				font-size: 16px;
			    margin-left: 11px;
			    width: 100%;
			    font-family: 'latolight';
			    color: #878787;

			    @media screen and (max-width: 768px) {
			    	font-size: 13px;
			    	margin-top: 1px;
			    	display: block;
			    	width: 100%;
			    }

			    @media screen and (max-width: 500px) {
			    	margin-left: 0;
			    	max-width: 100%;
			    }
			}
		}
	}
}