body {
	@media screen and (max-width: 768px) {
		padding-top: 80px;
	}
}

*, *:hover {
	outline: none !important;
}

.grey-background {
	background-color: $grey-background;
}

.line {
	border-top: 1px solid #61ce1b;
	margin-bottom:50px;
}

h1 {
	font-size: 33px;
	margin-bottom: 54px;

	&.font-lines {
		margin-top:34px;
		width: 100%;
		position: relative;

		&::before {
			margin: 0;
			left: 0;
			height: 2px;
			width: 100%
		}

		span {
			font-family: 'latolight';
			font-weight: 300;
		}
	}
}

.container {
	&.container-larger {
		@media screen and (min-width: 1340px) {
			width: 1325px;
		}
	}

	&.container-small {
		@media screen and (min-width: 768px) {
			width: 650px;
		}
	}
}

input,
textarea {
	&.form-control {
		border: 1px solid #51c237;
		-webkit-box-shadow: none hoff voff blur color;
		-moz-box-shadow: none hoff voff blur color;
		box-shadow: none hoff voff blur color;
		min-height: 70px;
		padding: 22px;
		font-size: 19px;
		font-family: 'latolight';
		font-weight: 300;
		
		&:hover,
		&:focus,
		&:active {
			-webkit-box-shadow: white hoff voff blur color;
			-moz-box-shadow: white hoff voff blur color;
			box-shadow: white hoff voff blur color;
		}
	}
}

textarea {
	&.form-control {
		height: 139px;
		min-height: auto;
	}
}



button,
input {
	&.btn-submit {
		background-color: #1e6910;
		border-color: #1e6910;
		color: white;
		font-size: 23px;
		height: 71px;
		text-align: center;
		text-transform: uppercase;
		font-family: 'latolight';
		font-weight: 300;
	}
}

.form-group {
	margin-bottom: 42px;
}