.gallery-page {
  .line {
    border-top: 1px solid #61ce1b;
    margin-bottom:16px;
  }

  h1 {
    font-size: 33px;
    margin-bottom: 54px;

    &.font-lines {
      margin-top:34px;
      width: 100%;
      position: relative;

      &::before {
        margin: 0;
        left: 0;
        height: 2px;
        width: 100%
      }

      span {
        font-family: 'latolight';
        font-weight: 300;
      }
    }
  }

  .current-image {
    background: #ffffff none no-repeat scroll center center / contain;
    height: 630px;
    width: 100%;
    position: relative;

    .prev,
    .next {
      width: 200px;
      height: 100%;
      position: absolute;
      top: 0;
      display: block;
      transition: opacity, 0.5s;
      opacity: 1;
      background: rgba(0, 0, 0, 0.38);
      background-repeat: no-repeat;
      background-position: center center;

      @media screen and (max-width: 600px) {
        width: 80px;
        background-size: 33px;
      }

      &:hover,
      &:active,
      &:focus {
        opacity: 0.25;
      }
    }

    .prev {
      left: 0;
      background-image: url('../images/gallery-arrow-left.png');
    }

    .next {
      right: 0;
      background-image: url('../images/gallery-arrow-right.png');
    }
  }

  .current-image-description {
    margin-bottom: 101px;
  }
}