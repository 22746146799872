footer {
	background-color: #262626;
	padding-top: 33px;
	padding-bottom: 31px;

	.col-left,
	.col-right {
		float: left;
		display: inline-block;
	}

	.col-left {
		width: 28%;
		text-align: right;
		padding-right: 27px;

		a {
			display: inline-block;
		}

		@media screen and (max-width: 992px) {
			text-align: left;
		}

		@media screen and (max-width: 768px) {
			text-align: center;
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;
			display: block;
		}
	}

	.col-right {
		width: 72%;
		padding-left: 41px;

		@media screen and (max-width: 992px) {
			padding-left: 0;
		}

		@media screen and (max-width: 768px) {
			text-align: center;
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;
			display: block;
		}

		nav {
			display: block;

			@media screen and (max-width: 768px) {
				margin-top: 15px;
				margin-bottom: 15px;
			}

			ul {
				display: block;

				li {
					display: inline-block;
					margin-right: 38px;

					@media screen and (max-width: 1200px) {
						margin-right: 31px;
					}

					@media screen and (max-width: 992px) {
						margin-right: 12px;
					}

					&:last-child {
						margin-right: 0;
					}

					a {
						width: 100%;
						color: #83878a;
						font-family: 'latoregular';
						text-transform: uppercase;
					}
				}
			}
		}

		p {
			color: white;
		    font-size: 10px;
		    line-height: 11px;
		    margin-top: 6px;
		    opacity: 0.15;

		    @media screen and (max-width: 992px) {
		    	text-align: justify;
		    }
		}
	}
}