.users-gallery {
	margin-top: 63px;

	h2 {
	    font-family: "latolight";
	    font-size: 56px;
	    letter-spacing: 1.8px;
	    line-height: 34px;
	    margin-bottom: 16px;
	    margin-top: 43px;

	    @media screen and (max-width: 768px) {
	    	font-size: 31px;
    		line-height: 35px;
    		margin-bottom: 10px;
	    }
	}

	h3 {
		@media screen and (max-width: 768px) {
			font-size: 18px;
    		line-height: 26px;
    		padding-left: 15px;
    		padding-right: 15px;
    		margin-top: 0;
		}
	}

	.gallery-long {
		display: flex;
		font-size: 0;
		flex-flow: row wrap;
		margin: 0 auto;
		margin-top: 35px;

		.single-image {
			flex: 0.67; 
			transition: .5s;
			min-width: 100px;
			height: 119px;

			@media screen and (max-width: 768px) {
				min-width: auto;
				height: 72px;
    			max-width: auto;
    			display: none;

    			@media screen and (max-width: 420px) {
    				width: 50%;
    				min-width: 50%;
    			}
			}

			&:hover,
			&:focus,
			&:active {
				opacity: 0.8;
				transition: opacity 0.5s ease 0s;
			}

			&.image-long {
				flex: 1;
				min-width: 200px;
				display: inline-block;
				
				@media screen and (max-width: 420px) {
    				width: 50%;
    				min-width: 50%;
    			}
			}

			&.image-medium {
				flex: 0.78;
				min-width: 150px;
			}

			&.has-video {
				position: relative;

				&:hover,
				&:focus,
				&:active {
					&::before,
					&::after {
						opacity: 0.5;
						transition: opacity 0.5s;
					}
				}

				&::before {
				    background: rgba(0, 0, 0, 0.14) none repeat scroll 0 0;
				    border: 2px solid white;
				    border-radius: 100%;
				    content: "";
				    height: 90px;
				    left: 50%;
				    opacity: 0.9;
				    position: absolute;
				    top: 50%;
				    transform: translate(-50%, -50%);
				    transition: opacity 0.5s ease 0s;
				    width: 90px;

				    @media screen and (max-width: 768px) {
				    	width: 30px;
				    	height: 30px;
				    }
				}

				&::after {
				    border-bottom: 20px solid transparent;
				    border-left: 32px solid white;
				    border-top: 20px solid transparent;
				    content: "";
				    height: 0;
				    left: 50%;
				    opacity: 0.9;
				    position: absolute;
				    top: 50%;
				    transform: translate(-39%, -50%);
				    transition: opacity 0.5s ease 0s;

				    @media screen and (max-width: 768px) {
				    	border-bottom: 6px solid transparent;
				    	border-left: 12px solid white;
				    	border-top: 6px solid transparent;
				    }
				}
			}

			img {
				width: 100%; 
				height: 100%; 
				object-fit: cover;
			}
		}
	}
}