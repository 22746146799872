/* Generated by Font Squirrel (https://www.fontsquirrel.com) on December 22, 2016 */

@font-face {
    font-family: 'latoblack';
    src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
         url('../fonts/lato-black-webfont.woff') format('woff'),
         url('../fonts/lato-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoblack_italic';
    src: url('../fonts/lato-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-blackitalic-webfont.woff') format('woff'),
         url('../fonts/lato-blackitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../fonts/lato-bold-webfont.woff') format('woff'),
         url('../fonts/lato-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latobold_italic';
    src: url('../fonts/lato-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-bolditalic-webfont.woff') format('woff'),
         url('../fonts/lato-bolditalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latohairline';
    src: url('../fonts/lato-hairline-webfont.woff2') format('woff2'),
         url('../fonts/lato-hairline-webfont.woff') format('woff'),
         url('../fonts/lato-hairline-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latohairline_italic';
    src: url('../fonts/lato-hairlineitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-hairlineitalic-webfont.woff') format('woff'),
         url('../fonts/lato-hairlineitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoitalic';
    src: url('../fonts/lato-italic-webfont.woff2') format('woff2'),
         url('../fonts/lato-italic-webfont.woff') format('woff'),
         url('../fonts/lato-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latolight';
    src: url('../fonts/lato-light-webfont.woff2') format('woff2'),
         url('../fonts/lato-light-webfont.woff') format('woff'),
         url('../fonts/lato-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latolight_italic';
    src: url('../fonts/lato-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-lightitalic-webfont.woff') format('woff'),
         url('../fonts/lato-lightitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff'),
         url('../fonts/lato-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'pfbeaubold';
    src: url('../fonts/pfbeausanspro-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'pfbeaulight';
    src: url('../fonts/pfbeausanspro-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'umberto';
    src: url('../fonts/umberto.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}