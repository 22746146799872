.article-page {
  margin-top: 22px;

  .article-main-image {
    height: 369px;
    width: 100%;
    -webkit-background-size:cover;
    background-size:cover;
    margin-bottom: 72px;
  }

  .top-content {
    .main-title {
      color: #fe11ab;
      font-family: latolight;
      font-size: 90px;
      font-weight: 200;
      margin-top: 4px;
      margin-bottom: 42px;
      line-height: 82px;

      @media screen and (max-width: 1200px) {
        font-size: 60px;
        line-height: 56px;
      }

      @media screen and (max-width: 768px) {
        font-size: 30px;
        line-height: 26px;
      }
    }

    p {
      margin-bottom: 27px;

      img {
        margin: 15px;
      }

      b {
        display: block;
        margin-bottom: 27px;
      }
    }

    aside {
      padding: 15px;
      background-color: #f6f6f6;
      margin-bottom: 30px;

      @media screen and (max-width: 992px) {
        display: inline-block;
      }

      h3 {
        font-size: 17px;
        font-weight: 300;
        margin-bottom: 31px;
        text-align: center;
        text-transform: uppercase;
      }

      .article-small {
        display: inline-block;
        margin-bottom: 25px;
        padding-left: 5px;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 992px) {
          width: 50%;
          float: left;
        }

        @media screen and (max-width: 500px) {
          min-height: 152px;
        }

        .main-image {
          display: inline-block;
          float: left;

          @media screen and (max-width: 500px) {
            width: 100%;
          }

          &.play-button {
            position: relative;

            &::before {
              background: rgba(0, 0, 0, 0.14) none repeat scroll 0 0;
              border: 2px solid white;
              border-radius: 100%;
              content: "";
              height: 26px;
              left: 50%;
              opacity: 0.9;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              transition: opacity 0.5s ease 0s;
              width: 26px;
            }

            &::after {
              border-bottom: 5px solid transparent;
              border-left: 8px solid white;
              border-top: 5px solid transparent;
              content: "";
              height: 0;
              left: 50%;
              opacity: 0.9;
              position: absolute;
              top: 50%;
              transform: translate(-39%, -50%);
              transition: opacity 0.5s ease 0s;
            }
          }

          img {
            display: block;
            width: 70px;
            height: 51px;
            object-fit: cover;

            @media screen and (max-width: 500px) {
              width: 100%;
              height: 100px;
            }
          }

          &:hover,
          &:focus,
          &:active {
            &.play-button {
              &::before,
              &::after {
                opacity: 0.5;
                transition: opacity 0.5s;
              }
            }
          }
        }

        .heading {
          color: black;
          display: inline-block;
          float: left;
          font-size: 16px;
          margin-left: 11px;
          margin-top: 0;
          max-width: calc(100% - 86px);
          text-decoration: none;
          width: auto;
          line-height: 16px;

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }

          @media screen and (max-width: 500px) {
            display: block;
            width: 100%;
            margin-left: 0;
            max-width: 100%;
          }

          i {
            font-size: 12px;
            vertical-align: middle;

            @media screen and (max-width: 500px) {
              display: none;
            }
          }
        }

        span {
          display: inline-block;
          float: left;
          max-width: calc(100% - 86px);
          font-size: 16px;
          margin-left: 11px;
          width: 100%;
          font-family: 'latolight';
          color: #878787;

          @media screen and (max-width: 768px) {
            font-size: 13px;
            margin-top: 1px;
            display: block;
            width: 100%;
          }

          @media screen and (max-width: 500px) {
            margin-left: 0;
            max-width: 100%;
          }
        }
      }
    }
  }

  .main-content {
    p {
      img {
        margin: 15px;

        @media screen and (max-width: 768px) {
          width: auto;
          max-width: 100%;
          margin-left: 0;
          margin-right: 0;
          float: none !important;
          display: block;
        }
      }
    }
  }
}

.users-article-comments {
  .well {
    &.comment {
      b {
        color: #fe11ab;
      }
    }
  }

  h3 {
    margin-bottom: 40px;
  }
}
.users-gallery-comments {
  .well {
    &.comment {
      b {
        color: #fe11ab;
      }
    }
  }

  h3 {
    margin-bottom: 40px;
  }

  .btn-submit {
    margin-bottom: 30px;
  }
}
