.login-page {
  //min-height: calc(100vh - 163px - 145px);

  .line {
    border-top: 1px solid #61ce1b;
    margin-bottom:50px;

    &.line-end {
      margin-bottom: 33px;
      margin-top: 47px;
    }
  }

  h1 {
    font-size: 33px;
    margin-bottom: 54px;

    @media screen and (max-width: 768px) {
      line-height: 38px;
      font-size: 31px;
    }

    &.font-lines {
      margin-top:34px;
      width: 100%;
      position: relative;

      &::before {
        margin: 0;
        left: 0;
        height: 2px;
        width: 100%
      }

      span {
        font-family: 'latolight';
        font-weight: 300;
      }
    }
  }

  form {
    margin-top: 34px;
  }
  
  a {
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline;
    color: black;
  }
  
  .container {
    /*
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    */
  }

  &+ .users-gallery {
    margin-top: 10px;
  }
}