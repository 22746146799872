.alert {
  padding: 25px;
  font-size: 17px;
  font-family: latolight;
  font-weight: 300;
}

.alert-console {
  background: black;

  pre {
    background: none;
    border: none;
  }
}