header {
	background-color: white;

	@media screen and (max-width: 768px) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 3;
		opacity: 0.9;
	}

	.user-navigation {
		background: #1eb102;
		background: -moz-linear-gradient(left,  #1eb102 0%, #1eb102 64%, #bbf43c 64%, #bbf43c 100%);
		background: -webkit-linear-gradient(left,  #1eb102 0%,#1eb102 64%,#bbf43c 64%,#bbf43c 100%);
		background: linear-gradient(to right,  #1eb102 0%,#1eb102 64%,#bbf43c 64%,#bbf43c 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1eb102', endColorstr='#bbf43c',GradientType=1 );

		@media screen and (-webkit-min-device-pixel-ratio:0) {
		    background: linear-gradient(to right, #1eb102 0%, #1eb102 73%, #bbf43c 64%, #bbf43c 100%);
		} 
		
		height: 48px;

		@media screen and (max-width: 768px) {
			display: none;
		}

		.pull-left {
			background-color: #83d474;
		    color: white;
		    font-family: "latoblack";
		    font-size: 11px;
		    min-height: 48px;
		    min-width: 126px;
		    padding: 15px 20px;
		    text-align: center;
		    text-transform: uppercase;
		    transition: opacity 0.5s ease 0s;

		    &:hover,
		    &:focus,
		    &:active {
		    	opacity: 0.5;
		    }
		}

		.user-actions {
			background-color: #bbf43c;
			min-height: 48px;
			min-width: 320px;

			.logged {
				padding-left: 11px;
				padding-top: 12px;
				display: inline-block;

				a {
					color: black;
				}
			}

			ul {
				display: inline-block;

				&.actions {
					min-height: 48px;
					padding-left: 52px;

					li {
						display: inline-block;
						
						a {
							color: #6da21d;
							text-decoration: none;
						}

						&.button {
							background-position: 0 2px;
						    background-repeat: no-repeat;
						    font-family: "latoblack";
						    font-size: 12px;
						    margin-right: 16px;
						    margin-top: 15px;
						    padding-left: 16px;
						    color: #6da21d;
						    transition: opacity 0.5s ease 0s;

						    &:hover,
						    &:focus,
						    &:active {
						    	cursor: pointer;
						    	opacity: 0.5;
						    }
						}

						&.login {
							background-image: url('../images/icon-lock.png');
						}

						&.search {
							background-image: url('../images/icon-search.png');
						}
					}
				}

				&.social {
					min-height: 48px;
					padding-top: 13px;

					li {
						display: inline-block;

						a {
							font-size: 15px;
    						margin-left: 7px;
    						transition: opacity 0.5s ease 0s;

    						&::hover,
    						&:focus,
    						&:active {
    							i {
    								opacity: 0.5;
    							}
    						}

							i {
								color: #639120;
							}
						}	
					}
				}
			}
		}
	}

	.site-navigation {
		min-height: 115px;
		background-color: white;

		.hamburger {
			background: #1eb102;
		    border: medium none;
		    border-radius: 10px;
		    height: 40px;
		    position: absolute;
		    right: 15px;
		    top: 20px;
		    width: 40px;
		    transition: background 0.5s;

		    &:hover {
		    	background: #bbf43c;
		    	transition: background 0.5s;
		    }

			span {
				width: 24px;
				height: 2px;
				position: absolute;
				display: block;
				background: white;
				left: 8px;
				top: 12px;

				&:nth-child(2) {
					top: 19px;
				}

				&:nth-child(3) {
					top: 26px;
				}
			}
		}

		@media screen and (max-width: 768px) {
			min-height: 80px;
		}

		.logo {
			display: inline-block;
		    margin-left: 31px;
		    margin-top: 19px;

		    @media screen and (max-width: 992px) {
		    	margin-left: 0;
		    }

		    @media screen and (max-width: 768px) {
		    	width: 100px;
		    	margin-top: 17px;

		    	img {
		    		width: 100%;
		    		height: auto;
		    	}
		    }
		}

		.no-padding {
			padding-right: 0;

			@media screen and (max-width: 1030px) {
				padding-right: 15px;
			}

			nav {
				margin-right: -22px;
			}
		}

		nav {
			display: inline-block;
			margin-top: 49px;

			@media screen and (max-width: 992px) {
				margin-top: 26px;
			}

			@media screen and (max-width: 768px) {
				display: none;
			}

			ul {
				display: inline-block;

				li {
					display: inline-block;

					@media screen and (max-width: 992px) {
						margin-bottom: 16px;
					}

					a {
						color: #302f2f;
					    font-family: "pfbeaulight";
					    font-size: 16px;
					    padding: 6px 20px;
					    text-transform: uppercase;
					    border: 2px solid transparent;

					    @media screen and (max-width: 1200px) {
					    	padding: 6px 13px;
					    }

					    &:hover,
					    &:focus,
					    &:active,
					    &.active {
					    	border: 2px solid #6d6d6d;
					    }
					}
				}
			}
		}
	}

	&.menu-toggle {
		.logo {
			z-index: 4;
			position: relative;
		}

		.hamburger {
			z-index: 4;
		}

		.user-navigation {
			background: white;

			@media screen and (max-width: 768px) {
				display: block;
			}

			@media screen and (min-width: 768px) {
				display: none;
			}

		    height: 200px;
		    position: fixed;
		    width: 100%;
		    z-index: 3;

		    .pull-left {
		    	float: none !important;
		    	display: none;
		    }

		    .user-actions {
		    	background: none;
		    	min-height: auto;
			    min-width: auto;
			    width: 100%;
			    margin-top: 80px;

			    ul {
			    	width: 100%;
			    	padding: 0;

			    	li {
			    		display: block;
			    		text-align: center;

			    		&.button {
			    			font-size: 16px;
			    			text-transform: uppercase;
			    			background: none;
			    			padding-left: 0;
			    			margin-right: 0;
			    			margin-top: 5px;

			    			i {
			    				display: none;
			    			}
			    		}
			    	}

			    	&.actions {
			    		width: 220px;
			    		display: block;
			    		margin: 0 auto;
			    	}

			    	&.social {
			    		display: block;
					    float: none !important;
					    height: auto;
					    margin: 15px auto 0;
					    min-height: auto;
					    padding-bottom: 15px;
					    text-align: center;
					    width: 300px;
					    border-bottom: 1px solid #bbf43c;

			    		li {
			    			display: inline-block;

			    			a {
			    				font-size: 20px;
							    margin-left: 10px;
							    margin-right: 10px;
			    			}
			    		}
			    	}
			    }
		    }
		}

		.site-navigation {
			nav {
				background: white;

			    @media screen and (max-width: 768px) {
					display: block;
				}

				@media screen and (min-width: 768px) {
					display: none;
				}

			    float: none;
			    height: calc(100% - 200px);
			    left: 0;
			    margin-top: 0;
			    position: fixed;
			    top: 200px;
			    width: 100%;
			    z-index: 3;
			    padding-top: 10px;

			    ul {
			    	display: block;
				    margin: 0 auto;
				    width: 300px;

				    li {
				    	width: 100%;
				    	margin-bottom: 3px;

				    	a {
				    		display: block;
						    padding: 3px;
						    text-align: center;
						    width: 100%;
						    line-height: 21px;
				    	}
				    }
			    }
			}
		}
	}
}