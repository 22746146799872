a:hover {
	text-decoration: none;
}

ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	margin-top: 0;
}

.pr-0 {
	padding-right: 0;
}