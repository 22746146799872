.articles-page {
  .line {
    border-top: 1px solid #61ce1b;
    margin-bottom: 50px;
  }

  h1.font-lines::before {
    margin: 0;
    left: 0;
    height: 2px;
    width: 100%;
  }

  h1.font-lines {
    margin-top: 34px;
    width: 100%;
    position: relative;
  }

  h1 {
    font-size: 33px;
    margin-bottom: 54px;
  }

  h1.font-lines span {
    font-family: 'latolight';
    font-weight: 300;
  }
  .font-lines span {
    position: relative;
    z-index: 3;
    background-color: white;
    padding-left: 30px;
    padding-right: 30px;
  }

  .font-lines::before {
    background-color: black;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    margin: 0 auto 0 -370px;
    position: absolute;
    top: 11px;
    vertical-align: middle;
    width: 740px;
    z-index: 1;
  }

  .top-content {
    margin-top: 100px;
  }

  .article-small {
    display: inline-block;
    margin-bottom: 30px;
    border-bottom: 1px solid #ededed;
    width: 100%;
    padding-bottom: 30px;

    .main-image {
      width: 320px;
      display: inline-block;
      float: left;
      margin-right: 15px;

      img {
        display: block;
        width: 100%;
      }
    }

    .heading {
      font-size: 22px;
      text-transform: uppercase;
      color: black;
      text-decoration: none;
      display: block;
    }

    .date {

    }
  }
}