.contact-page {
  .line {
    border-top: 1px solid #61ce1b;
    margin-bottom:50px;
  }
  
  h1 {
    font-size: 33px;
    margin-bottom: 54px;

    &.font-lines {
      margin-top:34px;
      width: 100%;
      position: relative;

      &::before {
        margin: 0;
        left: 0;
        height: 2px;
        width: 100%
      }

      span {
        font-family: 'latolight';
        font-weight: 300;
      }
    }
  }

  h2 {
    font-size: 25px;
    margin-bottom: 5px;
    margin-top: 0;
  }

  h3 {
    font-size: 23px;
    margin-top: 8px;
  }

  h4 {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: 2px;
  }

  h5 {
    font-size: 24px;
    line-height: 23px;
    margin-bottom: 10px;
    margin-top: 6px;

    i {
      margin-right: 18px;
    }
  }

  .padding {
    padding-top: 26px;
  }

  .google-map {
    float: none;
    height: 248px;
    margin: 0 auto 33px;

    #contact_map {
      background: black;
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .alert {
    margin-top: 30px;
  }

  form {
    margin-top: 33px;

    .btn-submit {
      margin-bottom: 53px;
    }
  }
}