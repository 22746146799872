.calculators-list {
	margin-top: 38px;
	margin-bottom: 50px;

	h2 {
	    font-family: "latolight";
	    font-size: 56px;
	    letter-spacing: 1.8px;
	    line-height: 34px;
	    margin-bottom: 16px;
	    margin-top: 43px;

	    @media screen and (max-width: 768px) {
	    	font-size: 31px;
    		line-height: 35px;
    		margin-bottom: 10px;
	    }
	}

	h3 {
		margin-bottom: 0;

		@media screen and (max-width: 768px) {
			font-size: 18px;
    		line-height: 26px;
    		padding-left: 15px;
    		padding-right: 15px;
    		margin-top: 0;
		}
	}

	.left-col,
	.right-col {
		display: inline-block;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
	}

	.left-col {
		width: 27%;
		background-image: url('../images/apple.png');
		background-repeat: no-repeat;
		position: center;
		height: 413px;

		@media screen and (max-width: 1200px) {
			background-size: contain;
		}

		@media screen and (max-width: 992px) {
			display: none;
		}
	}

	.right-col {
		width: 73%;
		padding-top: 45px;
		padding-bottom: 56px;

		@media screen and (max-width: 992px) {
			width: 100%;
		}

		@media screen and (max-width: 768px) {
			padding-bottom: 15px;
		}

		nav {
			padding-right: 60px;

			@media screen and (max-width: 992px) {
				padding-right: 15px;
				padding-left: 15px;
			}

			ul {
				display: flex;
			    flex-flow: row wrap;
			    justify-content: space-between;
			    list-style: outside none none;

				li {
					-moz-box-flex: 1;
				    border: 5px solid white;
				    color: #68dd8a;
				    flex-grow: 0;
				    font-size: 12px;
				    font-weight: 300;
				    min-height: 83px;
				    margin-bottom: 10px;
				    padding: 0;
				    text-align: center;
				    width: 283px;
				    display: flex;
				    justify-content: center;
				    align-items: center;

				    @media screen and (max-width: 1340px) {
				    	width: 245px;
				    }

				    @media screen and (max-width: 1200px) {
				    	width: 200px;
				    }

				    @media screen and (max-width: 768px) {
				    	flex-grow: 1;
				    	width: auto;
				    	margin-right: 10px;
				    	margin-bottom: 10px;
				    	min-height: 60px;
				    }

				    @media screen and (max-width: 510px) {
				    	width: 100%;
				    }


					a {
						-moz-box-align: center;
					    -moz-box-pack: center;
					    align-items: center;
					    color: black;
					    display: flex;
					    font-size: 17px;
					    height: 100%;
					    justify-content: center;
					    line-height: 18px;
					    padding: 10px 50px;
					    text-transform: uppercase;
					    width: 100%;
					    font-family: 'latoregular';
					    transition: background 0.5s ease 0s, color 0.5s ease 0s;

					    &:hover, 
					    &:focus,
					    &:active {
					    	background-color: #21b204;
					    	color: white;
					    }

					    @media screen and (max-width: 1200px) {
					    	font-size: 14px;
					    	line-height: 15px;
					    }

					    @media screen and (max-width: 510px) {
				    		padding: 10px;
				    	}
					}
				}
			}
		}
	}
}