.font-black {
	font-family: 'latoblack';
}

.font-bold {
	font-family: 'latobold';
}

.font-light {
	font-family: 'latolight';
}

.font-thin {
	font-family: 'latohairline';
}

.font-lines {
	margin-top: 0;
	line-height: 19px;
	position: relative;
	z-index: 2;

	@media screen and (max-width: 768px) {
		font-size: 18px;
		line-height: 26px;
	}

	span {
		position: relative;
		z-index: 3;
		background-color: white;
		padding-left: 30px;
		padding-right: 30px;

		@media screen and (max-width: 768px) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&::before {
		background-color: black;
	    content: "";
	    display: block;
	    height: 1px;
	    left: 50%;
	    margin: 0 auto 0 -370px;
	    position: absolute;
	    top: 11px;
	    vertical-align: middle;
	    width: 740px;
	    z-index: 1;

	    @media screen and (max-width: 992px) {
	    	margin: 0 auto 0 -360px;
	   	 	width: 720px;
		}

		@media screen and (max-width: 768px) {
			display: none;
		}
	}
}

.font-uppercase {
	text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: 'latoregular';
}

h1 {
	font-size: 56px;
    letter-spacing: 1.8px;
    line-height: 51px;

    @media screen and (max-width: 768px) {
    	font-size: 31px;
    	line-height: 35px;
    }
}

h3 {
	font-size: 26px;
}

b {
	font-family: 'latobold';
}

hr {
	width: 90%;
}

section h1 + h3 {
	line-height: 19px;
    margin-top: 0;
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
}