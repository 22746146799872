.icon-phone {
  display: inline-block;
  background-image: url(../images/icon-phone.png);
  width: 22px;
  height: 22px;
}

.icon-mail {
  display: inline-block;
  background-image: url(../images/icon-mail.png);
  width: 24px;
  height: 18px;
}