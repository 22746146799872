.search-page {
  .article-small {
    display: inline-block;
    margin-bottom: 30px;
    border-bottom: 1px solid #ededed;
    width: 100%;
    padding-bottom: 30px;

    .main-image {
      width: 320px;
      display: inline-block;
      float: left;
      margin-right: 15px;

      img {
        display: block;
        width: 100%;
      }
    }

    .heading {
      font-size: 22px;
      text-transform: uppercase;
      color: black;
      text-decoration: none;
      display: block;
    }
  }
}