.btn {
	font-size: 13px;
}

.btn-block {
	width: 100%;
	display: block;
}

.btn-grey {
	background-color: #f6f6f6;
	color: black;
	padding: 10px 15px;
	transition: color 0.3s ease 0s, background 0.3s ease 0s;

	&:hover,
	&:focus,
	&:active {
		
		color: #f6f6f6;
		background-color: black;
	}

	i {
		font-size: 11px;
    	margin-left: 10px;
	}
}