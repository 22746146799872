.category-list-grid,
.category-list-column {
	margin-top: 52px;

	h2 {
		font-family: "latolight";
	    font-size: 56px;
	    letter-spacing: 1.8px;
	    line-height: 34px;
	    margin-bottom: 16px;
	    margin-top: 43px;

	    @media screen and (max-width: 768px) {
	    	font-size: 31px;
    		line-height: 35px;
    		margin-bottom: 10px;
	    }
	}

	h3 {
		margin-bottom: 34px;

		@media screen and (max-width: 768px) {
			font-size: 18px;
    		line-height: 26px;
    		padding-left: 15px;
    		padding-right: 15px;
    		margin-top: 0;
		}
	}

	nav {
		display: block;
		background-color: white;
		padding: 4px;
		position: relative;

		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		&::after {
	    	position: absolute;
	    	bottom: 0;
	    	left: 0;
	    	width: 100%;
	    	height: 1px;
	    	background: #12ac14;
			background: -moz-linear-gradient(left,  #12ac14 0%, #90e241 100%);
			background: -webkit-linear-gradient(left,  #12ac14 0%,#90e241 100%);
			background: linear-gradient(to right,  #12ac14 0%,#90e241 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12ac14', endColorstr='#90e241',GradientType=1 );
			content: "";
			display: block;
	    }

		ul {
			display: block;
			text-align: center;

			li {
				display: inline-block;

				a {
					color: #000000;
				    display: block;
				    font-family: "latolight";
				    font-size: 16px;
				    margin-left: 25px;
				    margin-right: 25px;
				    opacity: 0.56;
				    padding: 1px;
				    transition: opacity 0.5s ease 0s;

				    @media screen and (max-width: 768px) {
				    	font-weight: 700;
				    }

				    &:hover,
				    &:focus,
				    &:active {
				    	opacity: 1;
				    }
				}
			}
		}
	}

	nav + .grey-background {
		padding: 44px 95px;

		@media screen and (max-width: 1200px) {
			padding: 15px 15px;
		}
	}

	.article-single {
		background-image: attr(background);
		background-size: cover;
	    height: 261px;
	    margin-bottom: 34px;
	    text-align: center;
	    position: relative;

    	h3 {
    		color: white;
		    display: inline-block;
		    font-family: "latobold";
		    font-size: 13px;
		    height: 26px;
		    margin: 0 auto;
		    min-width: 75px;
		    padding-top: 5px;
		    vertical-align: top;
		    background: #12ac14;
			background: -moz-linear-gradient(left,  #12ac14 0%, #90e241 100%);
			background: -webkit-linear-gradient(left,  #12ac14 0%,#90e241 100%);
			background: linear-gradient(to right,  #12ac14 0%,#90e241 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12ac14', endColorstr='#90e241',GradientType=1 );

			@media screen and (max-width: 768px) {
				padding-top: 0;
			}
    	}

    	.border {
    		background: rgba(0, 0, 0, 0.25);
		    border: 5px solid white;
		    display: block;
		    height: 153px;
		    margin: 21px auto 22px;
		    width: 302px;
		    padding: 15px;

		    @media screen and (max-width: 1200px) {
		    	width: 100%;
		    }

		    h2 {
		    	color: white;
			    font-family: "latohairline";
			    font-size: 26px;
			    margin-bottom: 4px;
			    margin-top: 24px;
			    text-transform: uppercase;
		    }

		    p {
		    	font-family: 'latoblack';
		    	color: white;
		    	font-size: 14px;
		    }
    	}

    	.rel {
    		background: #f6f6f6;
		    bottom: 0;
		    color: black;
		    display: block;
		    font-size: 13px;
		    left: 50%;
		    margin: 0 auto 0 -75px;
		    padding-bottom: 9px;
		    padding-top: 9px;
		    position: absolute;
		    width: 150px;
		    font-family: 'latobold';

		    &::before {
		    	position: absolute;
		    	top: 0;
		    	left: 0;
		    	width: 100%;
		    	height: 1px;
		    	background: #12ac14;
				background: -moz-linear-gradient(left,  #12ac14 0%, #90e241 100%);
				background: -webkit-linear-gradient(left,  #12ac14 0%,#90e241 100%);
				background: linear-gradient(to right,  #12ac14 0%,#90e241 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12ac14', endColorstr='#90e241',GradientType=1 );
				content: "";
				display: block;
		    }

		    &:hover,
		    &:focus,
		    &:active {
		    	background: #12ac14;
				background: -moz-linear-gradient(left,  #12ac14 0%, #90e241 100%);
				background: -webkit-linear-gradient(left,  #12ac14 0%,#90e241 100%);
				background: linear-gradient(to right,  #12ac14 0%,#90e241 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12ac14', endColorstr='#90e241',GradientType=1 );
				color: white;
		    }
    	}
	}

	.more-articles {
		color: black;
	    display: inline-block;
	    font-family: "latobold";
	    font-size: 13px;
	    margin-top: 15px;

		i {
			margin-left: 8px;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.category-list-column {
	margin-top: 0px;
	margin-bottom: 88px;

	.category {
		background-size: cover;
	    display: inline-block;
	    float: left;
	    height: 272px;
	    margin-bottom: 46px;
	    margin-top: 5px;
	    position: relative;
	    width: 14.2857%;
	    background-position: center;
	    transition: opacity 0.5s ease 0s, background 0.5s ease 0s;

	    &:hover,
	    &:focus,
	    &:active {
	    	opacity: 0.5;
	    }

	    @media screen and (max-width: 768px) {
	    	width: 25%;
	    }

	    @media screen and (max-width: 560px) {
	    	width: 33.3334%;
	    }

	    @media screen and (max-width: 420px) {
	    	width: 50%; 
	    }

		span {
			bottom: -32px;
		    color: black;
		    font-family: 'latohairline';
		    font-size: 24px;
		    height: 24px;
		    left: 0;
		    position: absolute;
		    text-align: center;
		    width: 100%;
		}

		&.category-1 {
			background-image: url('../images/category-1.jpg');
		}

		&.category-2 {
			background-image: url('../images/category-2.jpg');
		}

		&.category-3 {
			background-image: url('../images/category-3.jpg');
		}

		&.category-4 {
			background-image: url('../images/category-4.jpg');
		}

		&.category-5 {
			background-image: url('../images/category-5.jpg');
		}

		&.category-6 {
			background-image: url('../images/category-6.jpg');
		}

		&.category-7 {
			background-image: url('../images/category-7.jpg');
		}
	}
}

.recent-recipes {
	nav + .grey-background {
		padding: 39px 49px;

		@media screen and (max-width: 1200px) {
			padding: 15px;
		}
	}

	.more-articles {
		margin-top: 5px;
	}

	article {
		
		&.article-single {
			height: 324px;
			margin-bottom: 46px;
			position: relative;

			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.25);
				content: "";
				display: block;
				z-index: 1;
			}

			.rel {
				padding-bottom: 13px;
    			padding-top: 14px;
    			z-index: 3;

				&::before {
					display: none;
				}
			}
		}

		.rating {
			display: inline-block;
			width: 100%;
			margin-top: 16px;
			position: relative;
			z-index: 3;

			span {
				color: white;
			}

			.plus,
			.minus {
				background-position: center center;
			    background-repeat: no-repeat;
			    border: 1px solid white;
			    border-radius: 100%;
			    display: inline-block;
			    height: 31px;
			    width: 31px;
			    vertical-align: middle;
			}

			.plus {
				background-image: url('../images/rating-good.png');
				margin-right: 10px;
				transition: border 0.5s ease 0s, opacity 0.5s ease 0s, background 0.5s ease 0s;;

				&:hover,
				&:focus,
				&:active {
					border-color: #21b204;
					background-color: #21b204;
					opacity: 0.7;
				}

				input[type="submit"] {
					position: relative;
					opacity:0;
					width:100%;
					height:100%;
				}
			}

			.minus {
				background-image: url('../images/rating-bad.png');
				margin-left: 10px;
				transition: border 0.5s ease 0s, opacity 0.5s ease 0s, background 0.5s ease 0s;;

				&:hover,
				&:focus,
				&:active {
					border-color: red;
					background-color: red;
					opacity: 0.7;
				}

				input[type="submit"] {
					position: relative;
					opacity:0;
					width:100%;
					height:100%;
				}
			}

			.rating-full,
			.rating-empty {
				display: inline-block;
				height: 20px;
				width: 21px;
				vertical-align: middle;
			}

			.rating-full {
				background-image: url('../images/rating-full.png');
			}

			.rating-empty {
				background-image: url('../images/rating-empty.png');
			}
		}

		h2 {
			color: white;
		    font-family: "umberto";
		    font-size: 25px;
		    margin-top: 70px;
		    margin-bottom: 0px;
		    padding: 0px 15px;
		    z-index: 3;
		    position: relative;

		    @media screen and (max-width: 1340px) {
		    	margin-top: 25px;
		    }

		    @media screen and (max-width: 1200px) {
		    	margin-top: 15px;
		    }
		}

		p {
			color: white;
	    	font-size: 13px;
	    	padding: 0 15px;
	    	text-align: center;
	    	z-index: 3;
		    position: relative;
		}

		hr {
			border-color: white;
			margin-top: 12px;
			margin-bottom: 14px;
		}
	}
}

.category-list-link {
	margin-top: 25px;

	nav {
		display: inline-block;
		width: 20%;
		float: left;
		text-align: left;
		padding: 26px;

		@media screen and (max-width: 992px) {
			padding: 10px;
		}

		@media screen and (max-width: 768px) {
			padding: 0;
			width: 33.334%;
		}

		@media screen and (max-width: 600px) {
			width: 50%;
		}

		h3 {
			color: #21b204;
			font-family: 'latoblack';
			font-size: 23px;
		}

		li {
			margin-bottom: 5px;

			a {
				color: black;
				font-size: 17px;

				@media screen and (max-width: 460px) {
					font-size: 14px;
				}
			}
		}
	}
}